import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Offcanvas } from 'bootstrap'; 
import './Header.css';
import Logo from '../../assets/Images/logo.png';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    const offcanvasElement = document.querySelector('#offcanvasDarkNavbar');
    const offcanvasInstance = Offcanvas.getInstance(offcanvasElement); 
    if (offcanvasInstance) {
      offcanvasInstance.hide();
    }
    navigate(path);
    window.location.reload();
  };

  return (
    <nav
      className={`navbar navbar-dark fixed-top ${
        isScrolled ? 'scrolled black-background' : ''
      }`}
    >
      <div className="container-fluid header">
        <img src={Logo} alt="Sharks Club Logo" className={`logo ${isScrolled ? 'small-logo' : ''}`} />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasDarkNavbar"
          aria-controls="offcanvasDarkNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-fullscreen text-bg-dark"
          tabIndex="-1"
          id="offcanvasDarkNavbar"
          aria-labelledby="offcanvasDarkNavbarLabel"
          data-bs-backdrop="false"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-center flex-grow-1">
              <li className="nav-item">
                <a
                  href="/"
                  className="nav-link"
                  onClick={(event) => handleLinkClick(event, '/')}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/menu"
                  className="nav-link"
                  onClick={(event) => handleLinkClick(event, '/menu')}
                >
                  Our Menu
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/accommodation"
                  className="nav-link"
                  onClick={(event) => handleLinkClick(event, '/accommodation')}
                >
                  Accommodation
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="/contact-us"
                  className="nav-link"
                  onClick={(event) => handleLinkClick(event, '/contact-us')}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
