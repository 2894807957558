import React from 'react';
import '../assets/styles/Style.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import ButtonGlobal from '../Components/Buttons/Button';
import Carouselcomponent from '../Components/Carousel/Carousel';
import CarouselMenu from '../Components/Carousel/CarouselMenu';
import Food from "../assets/Images/01.png";
import Drink from "../assets/Images/02.png";
import Games from "../assets/Images/03.png";
import Image1 from "../assets/Images/kareoke.jpg";
import Image2 from "../assets/Images/dj.jpg";
import Gallery1 from "../assets/Images/01.jpg";
import Gallery2 from "../assets/Images/02.jpg";
import Gallery3 from "../assets/Images/03.jpg";
import Gallery4 from "../assets/Images/04.jpg";
import Gallery5 from "../assets/Images/05.jpg";
import Gallery6 from "../assets/Images/06.jpg";
import Brand1 from "../assets/Images/beerlogo1.svg";
import Brand2 from "../assets/Images/beerlogo2.svg";
import Brand3 from "../assets/Images/beerlogo3.svg";
import Brand4 from "../assets/Images/beerlogo4.svg";
import Brand5 from "../assets/Images/beerlogo5.svg";
import Brand6 from "../assets/Images/beerlogo6.svg";
import Brand7 from "../assets/Images/beerlogo7.svg";
import Bottle1 from "../assets/Images/beer1.svg";
import Bottle2 from "../assets/Images/beer2.svg";
import Bottle3 from "../assets/Images/beer3.svg";
import Bottle4 from "../assets/Images/beer4.svg";
import Bottle5 from "../assets/Images/beer5.svg";
import Bottle6 from "../assets/Images/beer6.svg";

const Home = () => {

  const bottles = [
    { id: 1, image: Bottle1, name: "Heineken 0.0", price: "$6.00" },
    { id: 2, image: Bottle2, name: "Molson Canadian", price: "$7.00" },
    { id: 3, image: Bottle3, name: "Corona Extra", price: "$7.50" },
    { id: 4, image: Bottle4, name: "Coors Lager", price: "$5.50" },
    { id: 5, image: Bottle5, name: "Miller Genuine", price: "$6.00" },
    { id: 6, image: Bottle6, name: "Corona Extra", price: "$7.00" },
    { id: 7, image: Bottle1, name: "Guinness", price: "$8.00" },
    { id: 8, image: Bottle2, name: "Stella Artois", price: "$6.50" },
    { id: 9, image: Bottle3, name: "Budweiser", price: "$5.00" },
    { id: 10, image: Bottle4, name: "Amstel Light", price: "$7.25" },
    { id: 11, image: Bottle5, name: "Peroni", price: "$6.75" },
    { id: 12, image: Bottle6, name: "Blue Moon", price: "$7.00" },
  ];

const brands = [Brand1, Brand2, Brand3, Brand4, Brand5, Brand6, Brand7];

const cardData = [
  {
    id: 1,
    title: "Delicious Food",
    description: "Savor delicious food in our cozy Sports bar restaurant.",
    image: Food, 
  },
  {
    id: 2,
    title: "Refreshing Drinks",
    description: "Enjoy our wide variety of refreshing drinks and cocktails.",
    image: Drink, 
  },
  {
    id: 3,
    title: "Entertainment",
    description: "Catch all the latest sports action while dining.",
    image: Games, 
  },
];


  return (
    <>

    {/* Hero Section */}
    <div className="hero-section d-flex flex-column justify-content-between text-center py-5">
      <div className="text-overlay mt-5">
        <h3 className="text-uppercase text-uppercase text-danger my-5">Bring All Your Friends Here</h3>
        <h1 className="display-4 fw-bold mb-3 title">Green View Peak The Perfect Choice</h1>
        <p className="lead banner-title py-5 ">
          The Perfect Choice for great food, drinks, and unbeatable sports entertainment, all in one vibrant venue         
        </p>
        <ButtonGlobal text='Explore Our Menu' to="/menu"/>

      <div className="social-media-section position-fixed d-flex flex-column align-items-center">
        <p className="text-uppercase  mb-5 vertical-text py-3">Follow us on</p>
          <div class="line-arrow py-2">
            <div class="line"></div>
            <div class="diamond"></div>
          </div>
        <div className="banner-icons d-flex flex-column mt-4">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon mb-3">
            <i className="bi bi-facebook"></i>
          </a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon mb-3">
            <i className="bi bi-instagram"></i>
          </a>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="social-icon">
            <i className="bi bi-youtube"></i>
          </a>
        </div>
      </div>

      </div>
    </div>

    {/* Second Section - Dining Entertainment */}

    <section className="dining-section py-5">
      <div className="container text-center ">
        <div className="row align-items-center my-5">
          <div className="col-md-5 text-md-start text-center mb-4 mb-md-0">
            <h4 className="text-danger text-uppercase fw-bold">
              Green View Peek
            </h4>
            <h2 className="fw-bold my-3">
              A Fusion of Dining and Sports Entertainment
            </h2>
          </div>

          <div className="col-md-6 text-md-start text-center">
            <div class="line-with-diamond"></div>
            <p className="text-dark left-text">
              A Fusion of Dining and Sports Entertainment at <br/>Green View Peek.
              Enjoy delicious meals while catching the latest sports action.
            </p>
          </div>
        </div>

        <div className="row">
         {cardData.map((card) => (
          <div className="col-md-4 mb-4" key={card.id}>
            <div className="card dining-card border-0 shadow-sm position-relative">
              <img
                src={card.image}
                className="card-img-top"
                alt={card.title}
              />
              <div className="card-body text-start">
                <h5 className="fw-bold text-white text-uppercase">{card.title}</h5>
                <p className="text-white text-left">{card.description}</p>
                <a href="123" className="arrow-link">
                  <span className="card-arrow">➔</span>
                </a>
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </section>

    {/* Third Section - Summary   */}
    <div className="container-fluid  text-white py-5 summary-section">
      <div className="container bg-danger summary">
        <div className="row">
          <div className="col-lg-5 d-flex flex-column justify-content-center">
            <h1 className="fw-bold">GREEN VIEW PEEK</h1>
            <h4 className="text-uppercase">Fuel & Fun</h4>
            <h2 className="fw-bold mt-3">Enhancing Your Feelings</h2>
          </div>
          <div className="col-lg-7 d-flex align-items-center justify-content-center">
            <div className="row w-100">
            <p  style={{fontSize: '20px'}}>
            From signature delights such as beef tartare and the best mashed potato in Paris,
            all the way to unique specialties such as thinly sliced Brittany artichokes with
            truffles.
            </p>
              <div className="col-6">
                <div className="bg-dark text-white p-4 text-letter">
                  <h3 className="fw-bold">200+</h3>
                  <p style={{fontSize: '20px'}}>Customers Daily</p>
                </div>
              </div>
            <div className="col-6">
              <div className="bg-dark text-white p-4 text-left">
                <h3 className="fw-bold">100+</h3>
                <p style={{fontSize: '20px'}}>Food and Drinks</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

    {/*Forth Section - Main menu */}
    <div className="container-fluid bg-dark text-white py-5 main-menu" style={{ minHeight: '100vh' }}>
      <div className=" text-center py-5 mt-5">
       
        <h5 className="text-danger text-uppercase my-4">Our Main Menu</h5>
        <h2 className="fw-bold mt-3">A Journey Through Taste</h2>
        <p className="mt-3 py-3">
          A Fusion of Dining and Sports Entertainment at Green View Peek: <br />
          Enjoy delicious meals while catching the latest sports action
        </p>

        <div className="row mt-5 w-100">
          <CarouselMenu />
        </div>

        <div className="mt-5 py-5">
          <ButtonGlobal text='Explore Our Menu'/>
        </div>
      </div>
    </div>

    {/* Fifth Section - Palete*/}
    <section className="elixirs-section py-5">
      <div className="container py-5">
        <div className="row mb-4">
          <div className="col-12 text-center">
            <h5 className="text-danger text-uppercase fw-bold ">
              Green Peek View Entertainment
            </h5>
            <h2 className="fw-bold">Crafted Elixirs for Every Palate</h2>
          </div>
        </div>

        <div className="row g-4">
          <div className="col-md-6">
            <div className="image-container">
              <img src={Image1} alt="Elixirs" className="img-fluid" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="image-container">
              <img src={Image2} alt="Entertainment" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Sixth Section - Beverage Section */}

    <section className="beverage-section py-5">
      <div className="container py-5">
        
        <div className="row mb-5 text-center">
          <div className="col-12">
            <h5 className="text-danger text-uppercase fw-bold">
              Green View Peek
            </h5>
            <h2 className="fw-bold text-white">Dive into the Experience</h2>
            <p className="lead">
              Capturing the Essence: Exploring Sharks Club and Grill
            </p>
          </div>
        </div>

        <Carouselcomponent bottles={bottles} />

        <div className="row mt-5 g-4 text-center">
         {brands.map((brand, index) => (
          <div key={index} className="col-custom">
            <div className="brand-logo">
             <img src={brand} alt={`Brand ${index + 1}`} className="img-fluid brand" />
            </div>
          </div>
         ))}
        </div>

      </div>
    </section>

    {/* Seventh Section - Experience Section */}

    <section className="experience-section py-5 my-5">
      <div className="container">
        <div className="row mb-5 text-center">
          <div className="col-12">
            <h5 className="text-danger text-uppercase fw-bold">
              Green View Peek
            </h5>
            <h2 className="fw-bold">Dive into the Experience</h2>
            <p className="text-muted">
              Capturing the Essence: Exploring Sharks Club and Grill
            </p>
          </div>
        </div>

      <div className="custom-grid-container">
        <div className="grid-item">
          <img src={Gallery1} alt="Gallery 1" className="img-fluid rounded" />
        </div>
        <div className="grid-item">
          <img src={Gallery3} alt="Gallery 2" className="img-fluid rounded" />
        </div>
        <div className="grid-item">
          <img src={Gallery5} alt="Gallery 3" className="img-fluid rounded" />
        </div>
        <div className="grid-item">
          <img src={Gallery2} alt="Gallery 4" className="img-fluid rounded" />
        </div>
        <div className="grid-item">
          <img src={Gallery4} alt="Gallery 5" className="img-fluid rounded" />
        </div>
        <div className="grid-item">
          <img src={Gallery6} alt="Gallery 6" className="img-fluid rounded" />
        </div>
      </div>

      </div>
    </section>
    
    </>
  );
};

export default Home;

