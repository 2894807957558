import { Carousel } from 'react-bootstrap';
import Room1 from '../../assets/Images/img1.jpg';
import Room2 from '../../assets/Images/img2.jpg';
import Room3 from '../../assets/Images/img3.jpg';
import Room4 from '../../assets/Images/img4.jpg';
import Room5 from '../../assets/Images/img5.jpg';
import Room6 from '../../assets/Images/img6.jpg';
import Room7 from '../../assets/Images/img7.jpg';
import Room8 from '../../assets/Images/img8.jpg';
import Room9 from '../../assets/Images/img9.jpg';
import Room10 from '../../assets/Images/img10.jpg';
import Room11 from '../../assets/Images/img11.jpg';
import Room12 from '../../assets/Images/img12.jpg';
import Room13 from '../../assets/Images/img13.jpg';
import Room14 from '../../assets/Images/img14.jpg';
import Room15 from '../../assets/Images/img15.jpg';
import Room16 from '../../assets/Images/img16.jpg';
import Room17 from '../../assets/Images/img17.jpg';
import Room18 from '../../assets/Images/img18.jpg';
import Room19 from '../../assets/Images/img19.jpg';
import Room20 from '../../assets/Images/img20.jpg';
import Room21 from '../../assets/Images/img21.jpg';
import "./ImageSlider.css";

const ImageSlider = () => {
  const rooms = [
    Room1, Room2, Room3, Room4, Room5, Room6, Room7, Room8,
    Room9, Room10, Room11, Room12, Room13, Room14, Room15,
    Room16, Room17, Room18, Room19, Room20, Room21,
  ];

  const designs = [
    Room1, Room2, Room3, Room4, Room5, Room6, Room7, Room8,
    Room9, Room10, Room11, Room12, Room13, Room14, Room15,
    Room16, Room17, Room18, Room19, Room20, Room21,
  ];

  return (
    <>
      <div className="slider-container">
        <Carousel className="slider-carousel" controls={false} indicators={false} interval={4000}>
          {rooms.map((room, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100 slider-image"
                src={room}
                alt={`Room ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="logo-carousel-container">
        <div className="logo-carousel">
          {designs.map((logo, index) => (
            <div className="logo-item" key={index}>
              <img src={logo} alt={`Logo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ImageSlider;
