import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import './Footer.css';
import Logo from '../../assets/Images/logo.png';

const Footer = () => {
  return (
    <footer className="footer-section text-center py-5">
      
      <div className="d-flex justify-content-center mb-4">
        <img src={Logo} alt="Sharks Club Logo" className="footer-logo" />
      </div>

      <div className="container">
        <div className="row text-center">

         <div className="col-md-4">
            <h4 className="footer-heading">Open Hours</h4>
            <p>
              Mon - Thu : 11 am - 11 pm
              <br />
              Fri - Sun : 11 am - 2 pm
            </p>
          </div>

          <div className="col-md-4">
            <h4 className="footer-heading">Location</h4>
            <p>
              52 AI-Mu'tadid Billah Street,
              <br />
              Gampala
            </p>
          </div>

          <div className="col-md-4">
            <h4 className="footer-heading">Contact</h4>
            <p>
              info@greenviewpeak.com
              <br />
              (+1) 123-4567-999
            </p>
          </div>

        </div>
      </div>

      <div className="social-icons">
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="social-icon facebook-icon" />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="social-icon instagram-icon" />
        </a>
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
          <FaYoutube className="social-icon youtube-icon" />
        </a>
      </div>

      <div className="footer-bottom mt-5 py-5">
        <p className="mb-0" style={{lineHeight: '1.5rem'}}>&copy; 2024 Green View & Peak. All Rights Reserved.
        <p className="mb-0" style={{fontSize: '0.8rem'}}>Website design and IT Solution : 
          <a href='https://www.adada.com.au/' className='text-danger fw-bold' style={{textDecoration: 'none'}}> ADADA Digital</a>
        </p>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

