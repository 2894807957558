import ButtonGlobal from '../Components/Buttons/Button';
import InnerBanner from '../Components/InnerBanner/InnerBanner';

const ContactUs = () => {

  return (
    <>
      <InnerBanner
        mainTitle="Our Main Menu"
        bannerTitle="Contact Us"
        bannerdescription="A Fusion of Dining and Sports Entertainment at Sharks Club and Grill: Enjoy delicious meals while catching the latest sports action"
      />

    <div className="container my-5 py-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.7086989953!2d80.56104347574772!3d7.159637415442658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae37189ed7df0f5%3A0x5c58bcf26e2b671c!2sGreen%20View%20Peak!5e0!3m2!1sen!2slk!4v1732246525669!5m2!1sen!2slk" 
              width="100%"
              height="490"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Maps"
            ></iframe>
          </div>
        </div>

        <div className="col-md-6">
          <form>
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">First Name</label>
              <input type="text" className="form-control" id="firstName" placeholder="Enter your first name"/>
            </div>

            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">Last Name</label>
              <input type="text" className="form-control" id="lastName" placeholder="Enter your last name"/>
            </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message
          </label>
          <textarea
            className="form-control"
            id="message"
            rows="4"
            placeholder="Write your message here"
          ></textarea>
        </div>

        <div className="d-grid">
          <ButtonGlobal text="Submit" />
        </div>
      </form>
    </div>

  </div>
</div>

    </>
  );
};

export default ContactUs;