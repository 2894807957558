import '../assets/styles/accommodation.css';
import ImageSlider from '../Components/ImageSlider/ImageSlider';
import InnerBanner from '../Components/InnerBanner/InnerBanner';
import RoomOption1 from '../assets/Images/img5.jpg';
import RoomOption2 from '../assets/Images/img24.jpg';
import RoomOption3 from '../assets/Images/img15.jpg';
import Features1 from '../assets/Images/img30.jpg';
import Features2 from '../assets/Images/img33.jpg';
import Features3 from '../assets/Images/img1.jpg';

const Accommodation = () => {
  return (
    <>
      {/* Inner Banner */}
      <InnerBanner
        mainTitle="Our Designed Accommodations"
        bannerTitle="A Journey To Exploring The Wonders Of Gampola"
        bannerdescription="A Fusion of Dining and Sports Entertainment at Sharks Club and Grill: Enjoy delicious meals while catching the latest sports action."
      />
      <div className='mt-5 py-5'>
      <ImageSlider/>
      </div>

      <div className="container mb-5 py-5">
        <div className="row align-items-center m-0">
          <div className="col-md-6 text-md-start text-center mb-4 mb-md-0">
            <div class="line-diamond"></div>
            <h4 className="text-danger text-uppercase fw-bold">
              Accommodation
            </h4>
            <h2 className="fw-bold my-3">
              Comfort Meets Tranquility
            </h2>
          </div>

          <div className="col-md-6 text-md-start text-center">
            <p className="text-dark left-text" style={{textAlign: 'justify'}}>
              At <strong>Green View Peak</strong>, we offer a variety of thoughtfully designed accommodations to provide you with a restful stay amidst the lush hill country of Sri Lanka. Each room blends comfort, functionality, and scenic charm to create a serene retreat for travelers of all kinds.
            </p>
          </div>
        </div>

        {/* Room Details */}
        <div className="container my-5">
          <div className="row align-items-center">
        
          <div className="col-md-6">
            <div className="grid-gallery-wrapper">
              <div className="grid-gallery">
                <div className="large-image">
                  <img src={RoomOption1} alt="Large View" />
                 </div>
                <div className="small-image">
                  <img src={RoomOption2} alt="Small View 1" />
                </div>
                <div className="small-image">
                  <img src={RoomOption3} alt="Small View 2" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 room-content">
            <div >
              <h3 className="my-4" style={{fontSize:'1.8rem', fontFamily: '"Noto Serif", serif'}}>Room Options to Suit Every Traveler</h3>
              <p className="text-dark" style={{fontSize:'1.2rem', textAlign: 'justify', lineHeight: '1.6rem'}}>
                Whether you’re a solo adventurer, a couple seeking a romantic
                getaway, or a family exploring together, our range of rooms
                ensures there’s a perfect fit for everyone:
              </p>
              <ul>
                
                <li className='mb-2' style={{ fontSize: '1.2rem', textAlign: 'justify' }}>
                  <strong>Single Rooms:</strong> Ideal for solo travelers, equipped with a cozy bed, a work desk, and essential amenities for a relaxing stay.
                </li>

                <li className='mb-2' style={{fontSize:'1.2rem', textAlign: 'justify'}}>
                  <strong>Double Rooms:</strong> Perfect for couples, these rooms
                  feature spacious layouts, balconies with mountain views, and a
                  comfortable bed for two.
                </li>

                <li style={{fontSize:'1.2rem', textAlign: 'justify'}}>
                  <strong>Family Rooms:</strong> Generously sized to accommodate
                  small families, these rooms include additional space and seating
                  areas for shared moments.
                </li>
              </ul>
            </div>
          </div>
          </div>
        </div>

        {/* Features and Amenities */}
        <div className="container my-5 py-5">
          <div className="row align-items-center justify-content-center ">

          <div className="col-md-6 room-content">
            <div >
              <h3 className="my-4" style={{fontSize:'1.8rem', fontFamily: '"Noto Serif", serif'}}>Key Features and Amenities</h3>

              <ul>
                <li className='mb-2' style={{fontSize:'1.2rem', textAlign: 'justify'}}>
                  <strong>Private Bathrooms:</strong> Each room boasts a private
                  bathroom with hot showers and complimentary toiletries to ensure
                  convenience and comfort.
                </li>

                <li className='mb-2' style={{fontSize:'1.2rem', textAlign: 'justify'}}>
                  <strong>Breathtaking Views:</strong> Wake up to panoramic vistas
                  of the hill country, including the iconic Ambuluwawa Tower,
                  visible from select rooms.
                </li>

                <li className='mb-2' style={{fontSize:'1.2rem', textAlign: 'justify'}}>
                  <strong>Safety and Comfort:</strong> All rooms are equipped with
                  safety deposit boxes and are designed to provide a peaceful
                  environment for rest.
                </li>

                <li style={{fontSize:'1.2rem', textAlign: 'justify'}}>
                  <strong>Climate Control:</strong> Enjoy a comfortable temperature
                  with the availability of air conditioning and fans in most rooms.
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 ">
            <div className="grid-gallery-wrapper">
              <div className="grid-gallery">
                <div className="large-image">
                  <img src={Features1} alt="Large View" />
                 </div>
                <div className="small-image">
                  <img src={Features2} alt="Small View 1" />
                </div>
                <div className="small-image">
                  <img src={Features3} alt="Small View 2" />
                </div>
              </div>
            </div>
          </div> 
          </div>
        </div>

        {/* Guest Reviews */}
        <div className="mt-5">
          <h3 className="my-4 " style={{fontSize:'1.8rem', fontFamily: '"Noto Serif", serif'}}>What Our Guests Say</h3>
          <p className="text-dark" style={{fontSize:'1.2rem', textAlign: 'justify'}}>
            Our guests frequently commend the spaciousness and cleanliness of
            the rooms, as well as the welcoming ambiance they create. One guest
            noted, <strong><em>“Big and comfy rooms with a great rooftop bar and stunning views”</em></strong>. Families and solo travelers alike appreciate the thoughtful
            touches, such as mosquito lamps and friendly service.
          </p>
        </div>

        {/* Stay with Us */}
        <div className="mt-5">
          <h3 className="my-4" style={{fontSize:'1.8rem', fontFamily: '"Noto Serif", serif'}}>Stay with Us</h3>
          <p className="text-dark" style={{fontSize:'1.2rem', textAlign: 'justify'}}>
            After a day of exploring the wonders of Gampola and the surrounding
            hill country, unwind in the comfort of your room at{" "}
            <strong>Green View Peak</strong>. Whether you’re here for adventure
            or relaxation, our accommodations provide the perfect base for a
            memorable journey.
          </p>
          <p className="text-dark" style={{fontSize:'1.2rem', textAlign: 'justify'}}>
            Book your stay today and discover why{" "}
            <strong>Green View Peak</strong> is a favorite for travelers from
            around the world!
          </p>

          {/* Booking Button */}
          <a
            href="https://www.google.com/search?q=green+view+peak+gampola"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-danger btn-lg mt-5"
          >
            Book Your Accommodations Now!
          </a>
        </div>


      </div>
    </>
  );
};

export default Accommodation;
