import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';

const CarouselComponent = ({ bottles }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(6);

  const interval = 2000;

  const updateSlidesToShow = () => {
    if (window.innerWidth <= 480) {
      setSlidesToShow(1); 
    } else if (window.innerWidth <= 768) {
      setSlidesToShow(3); 
    } else {
      setSlidesToShow(6); 
    }
  };

  useEffect(() => {
    updateSlidesToShow(); 
    window.addEventListener("resize", updateSlidesToShow); 
    return () => {
      window.removeEventListener("resize", updateSlidesToShow); 
    };
  }, []);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      handleNext();
    }, interval);

    return () => clearInterval(slideInterval);
  }, [activeIndex, interval]);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % bottles.length);
  };

  const getCurrentBottle = () => {
    const length = bottles.length;
    const currentItems = [];
    for (let i = 0; i < slidesToShow; i++) {
      currentItems.push(bottles[(activeIndex + i) % length]);
    }
    return currentItems;
  };

  return (
    <>
      <Carousel indicators={false} controls={false} interval={interval} className="mt-4">
        <Carousel.Item>
          <div
            className="d-flex justify-content-center align-items-center carousel-wrapper"
            style={{
              flexDirection: slidesToShow === 1 ? "column" : "row",
              maxWidth: "1200px", 
              margin: "0 auto",
              padding: '20px',
              gap: "30px"
            }}
          >
            {getCurrentBottle().map((bottle, index) => (
              <div
                key={index}
                className="d-flex flex-column align-items-center"
                style={{
                  width: slidesToShow === 1 ? "70%" : "200px", 
                  textAlign: "center",
                  margin: slidesToShow === 1 ? "0 auto" : "0",
                  background: "#333",
                  padding: '20px',
                }}
              >
                <img
                  src={bottle.image}
                  alt={bottle.name}
                  className="img-fluid"
                  style={{
                    maxHeight: slidesToShow === 1 ? "90vh" : "300px",
                    objectFit: "contain",
                    marginBottom: "10px",
                  }}
                />
                <div style={{ textAlign: "center", color: "#fff" }}>
                  <p className="text-white mt-3 mb-1 fw-bold">{bottle.name}</p>
                  <p className="text-success fw-bold">{bottle.price}</p>
                </div>
              </div>
            ))}
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default CarouselComponent;
