import CarouselMenu from '../Components/Carousel/CarouselMenu';
import '../assets/styles/menu.css';
import { Carousel } from 'react-bootstrap';
import menuData from '../Pages/menuData'; 
import Starter from "../assets/Images/Starter2.png";
import Burger from "../assets/Images/burger2.jpg";
import SaladAndSoup from "../assets/Images/salad2.jpg";

const Menu = () => {
  const { starterMenuItems = [], mainCourseMenuItems = [], dessertMenuItems = [] } = menuData;

  return (
    <>
      {/* Inner Banner */}
      <div className="inner-banner d-flex flex-column text-center py-5">
        <div className="innerbanner-text">
          <h3 className="text-uppercase text-white my-2">Our Main Menu</h3>
          <h1 className="display-4 fw-bold innerbanner-title my-3">A Journey Through Taste</h1>
          <p className="lead">
            A Fusion of Dining and Sports Entertainment at Sharks Club and Grill: <br />
            Enjoy delicious meals while catching the latest sports action
          </p>
        </div>
        <CarouselMenu />
      </div>

      {/* Menu Section */}
      <div className="menu-book container text-center py-3">
        <Carousel indicators={true} controls={false} interval={null}>
          {/* Starter Page */}
          <Carousel.Item>
            <div className="menu-section container text-center py-5 my-5">
            <h2 className="text-white mb-4">
              <span className="starter-style">Starter</span>
            </h2>
              <img src={Starter} className="card-img-top mb-4" alt="Starter" />
              <div className="row my-5">
                {starterMenuItems.map((item, index) => (
                  <div className="col-md-6 mb-4" key={index}>
                    <div className="card text-white">
                      <div className="card-body text-start">
                        <h5 className="card-title">{item.title}</h5>
                        <p className="card-text">{item.description}</p>
                        <h5 className="text-warning">{item.price}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Carousel.Item>

          {/* Main Course Page */}
          <Carousel.Item>
            <div className="menu-section container text-center py-5 my-5">
              <h2 className="text-white mb-4">Salad And Soup</h2>
              <img src={SaladAndSoup} className="card-img-top mb-4" alt="Main Course" />
              <div className="row my-5">
                {mainCourseMenuItems.map((item, index) => (
                  <div className="col-md-6 mb-4" key={index}>
                    <div className="card text-white">
                      <div className="card-body text-start">
                        <h5 className="card-title">{item.title}</h5>
                        <p className="card-text">{item.description}</p>
                        <h5 className="text-warning">{item.price}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Carousel.Item>

          {/* Dessert Page */}
          <Carousel.Item>
            <div className="menu-section container text-center py-5 my-5">
              <h2 className="text-white mb-4">Burger</h2>
              <img src={Burger} className="card-img-top mb-4" alt="Dessert" />
              <div className="row my-5">
                {dessertMenuItems.map((item, index) => (
                  <div className="col-md-6 mb-4" key={index}>
                    <div className="card text-white">
                      <div className="card-body text-start">
                        <h5 className="card-title">{item.title}</h5>
                        <p className="card-text">{item.description}</p>
                        <h5 className="text-warning">{item.price}</h5>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default Menu;
