import React from "react";
import './InnerBanner.css';

const InnerBanner = ({ mainTitle, bannerTitle, bannerdescription }) => {
  return (
    <div className="inner-banner d-flex flex-column justify-content-between text-center py-5">
      <div className="banner-text py-5 my-5">
        <h3 className="text-uppercase text-white mt-5">{mainTitle}</h3>
        <h1 className="display-4 fw-bold ">{bannerTitle}</h1>
        <p className="lead banner-title">{bannerdescription}</p>
      </div>
    </div>
  );
};

export default InnerBanner;