import React, { useState, useEffect, useCallback } from "react";
import Starter from "../../assets/Images/wings.jpg";
import Sandwich from "../../assets/Images/sandwich.jpg";
import Pasta from "../../assets/Images/pasta.jpg";
import Burger from "../../assets/Images/burger.png";

const CarouselMenu = () => {
  const items = [
    { src: Starter, alt: "Starter", label: "Starter" },
    { src: Sandwich, alt: "Sandwich", label: "Sandwich" },
    { src: Pasta, alt: "Pasta", label: "Pasta" },
    { src: Burger, alt: "Burger", label: "Burger" },
    { src: Starter, alt: "Starter 2", label: "Starter 2" },
    { src: Sandwich, alt: "Sandwich 2", label: "Sandwich 2" },
    { src: Pasta, alt: "Pasta2", label: "Pasta2" },
    { src: Burger, alt: "Burger2", label: "Burger2" },
  ];

  const [activeIndex, setActiveIndex] = useState(0); 
  const [slidesToShow, setSlidesToShow] = useState(4); 
  const interval = 3000;

  const updateSlidesToShow = useCallback(() => {
    if (window.innerWidth <= 480) {
      setSlidesToShow(1); 
    } else if (window.innerWidth <= 768) {
      setSlidesToShow(2); 
    } else {
      setSlidesToShow(4); 
    }
  }, []);

  useEffect(() => {
    updateSlidesToShow(); 
    window.addEventListener("resize", updateSlidesToShow); 
    return () => {
      window.removeEventListener("resize", updateSlidesToShow); 
    };
  }, [updateSlidesToShow]);

  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
  }, [items.length]);

  const getCurrentItems = () => {
    const length = items.length;
    const visibleItems = [];
    for (let i = 0; i < slidesToShow; i++) {
      visibleItems.push(items[(activeIndex + i) % length]);
    }
    return visibleItems;
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      handleNext();
    }, interval);

    return () => clearInterval(slideInterval);
  }, [handleNext, interval]);

  return (
    <div className="carousel-container w-100" style={{ width: "100%" }}>
      <div className="d-flex justify-content-center">
        {getCurrentItems().map((item, index) => (
          <div
            key={index}
            className="card bg-dark text-white border-0 mx-2"
            style={{
              flex: "1",
              maxWidth: `${100 / slidesToShow - 2}%`,
              height: "350px",
            }}
          >
            <img
              src={item.src}
              alt={item.alt}
              className="card-img"
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
            <div className="card-img-overlay-bottom">
              <h5 className="text-uppercase text-white fw-bold">
                {item.label}
              </h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarouselMenu;
