import React from 'react';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from '../src/Components/Footer/Footer';
import Home from '../src/Pages/Home' 
import Header from './Components/Header/Header';
import ContactUs from './Pages/ContactUs';
import Accommodation from './Pages/Accommodation';
import Menu from './Pages/Menu';

function App() {
    return (
    <Router>
      <div className="App"> 
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/accommodation" element={<Accommodation />} />
        </Routes>
        <Footer />
      </div>
    </Router>
    );
}

export default App;
