const menuData = {

starterMenuItems: [
    {
      title: 'Deep Fried Macaroni & Cheese',
      price: '$13',
      description: 'Blend of real cheddar cheese and creamy macaroni coated with a crispy batter and deep fried. Served with jalapeño dip.',
    },
    {
      title: 'Coconut Shrimp',
      price: '$15',
      description: 'Served with sweet chili Thai sauce.',
    },
    {
      title: 'Crispy Calamari',
      price: '$17',
      description: 'Lightly breaded calamari rings deep fried and served with roasted garlic aioli dip.',
    },
    {
      title: 'Crispy Chicken Bites',
      price: '$14',
      description: 'Crispy chicken bites tossed in sweet chili sauce served with French fries.',
    },
    {
      title: 'Nachos Grande',
      price: '$19',
      description: 'Multi-colored corn chips loaded with Tex-Mex cheese, tomatoes, lettuce, green onions, black olives, and jalapeños. Served with sour cream and salsa.',
    },
    {
      title: 'Nachos Supreme',
      price: '$22',
      description: 'Multi-colored corn chips loaded with Tex-Mex cheese, black beans, bell peppers, tomatoes, lettuce, green onions, red onions, black olives, and jalapeños. Served with sour cream and salsa.',
    },
    {
      title: 'Stuffed Potato Skins',
      price: '$13',
      description: 'Carved potatoes brilliantly stuffed with BBQ sauce, bacon bits, and Tex-Mex cheese. Served with sour cream.',
    },
    {
      title: 'Perogies',
      price: '$14',
      description: 'Topped with sautéed onions, bacon, and cheese. Served with sour cream.',
    },
    {
      title: 'Feta Bruschetta',
      price: '$13',
      description: 'Tomatoes, garlic, red onions, fresh basil, and olive oil served on garlic toast with feta cheese, drizzled with balsamic glaze.',
    },
    {
      title: 'Crab Cakes',
      price: '$16',
      description: 'Served with jalapeño dip.',
    },
  ],

  mainCourseMenuItems: [
    {
      title: 'Deep Fried Macaroni & Cheese',
      price: '$13',
      description: 'Blend of real cheddar cheese and creamy macaroni coated with a crispy batter and deep fried. Served with jalapeño dip.',
    },
    {
      title: 'Coconut Shrimp',
      price: '$15',
      description: 'Served with sweet chili Thai sauce.',
    },
    {
      title: 'Crispy Calamari',
      price: '$17',
      description: 'Lightly breaded calamari rings deep fried and served with roasted garlic aioli dip.',
    },
    {
      title: 'Crispy Chicken Bites',
      price: '$14',
      description: 'Crispy chicken bites tossed in sweet chili sauce served with French fries.',
    },
    {
      title: 'Nachos Grande',
      price: '$19',
      description: 'Multi-colored corn chips loaded with Tex-Mex cheese, tomatoes, lettuce, green onions, black olives, and jalapeños. Served with sour cream and salsa.',
    },
    {
      title: 'Nachos Supreme',
      price: '$22',
      description: 'Multi-colored corn chips loaded with Tex-Mex cheese, black beans, bell peppers, tomatoes, lettuce, green onions, red onions, black olives, and jalapeños. Served with sour cream and salsa.',
    },
    {
      title: 'Stuffed Potato Skins',
      price: '$13',
      description: 'Carved potatoes brilliantly stuffed with BBQ sauce, bacon bits, and Tex-Mex cheese. Served with sour cream.',
    },
    {
      title: 'Perogies',
      price: '$14',
      description: 'Topped with sautéed onions, bacon, and cheese. Served with sour cream.',
    },
    {
      title: 'Feta Bruschetta',
      price: '$13',
      description: 'Tomatoes, garlic, red onions, fresh basil, and olive oil served on garlic toast with feta cheese, drizzled with balsamic glaze.',
    },
    {
      title: 'Crab Cakes',
      price: '$16',
      description: 'Served with jalapeño dip.',
    },
  ],

  dessertMenuItems:[
    {
      title: 'Deep Fried Macaroni & Cheese',
      price: '$13',
      description: 'Blend of real cheddar cheese and creamy macaroni coated with a crispy batter and deep fried. Served with jalapeño dip.',
    },
    {
      title: 'Coconut Shrimp',
      price: '$15',
      description: 'Served with sweet chili Thai sauce.',
    },
    {
      title: 'Crispy Calamari',
      price: '$17',
      description: 'Lightly breaded calamari rings deep fried and served with roasted garlic aioli dip.',
    },
    {
      title: 'Crispy Chicken Bites',
      price: '$14',
      description: 'Crispy chicken bites tossed in sweet chili sauce served with French fries.',
    },
    {
      title: 'Nachos Grande',
      price: '$19',
      description: 'Multi-colored corn chips loaded with Tex-Mex cheese, tomatoes, lettuce, green onions, black olives, and jalapeños. Served with sour cream and salsa.',
    },
    {
      title: 'Nachos Supreme',
      price: '$22',
      description: 'Multi-colored corn chips loaded with Tex-Mex cheese, black beans, bell peppers, tomatoes, lettuce, green onions, red onions, black olives, and jalapeños. Served with sour cream and salsa.',
    },
    {
      title: 'Stuffed Potato Skins',
      price: '$13',
      description: 'Carved potatoes brilliantly stuffed with BBQ sauce, bacon bits, and Tex-Mex cheese. Served with sour cream.',
    },
    {
      title: 'Perogies',
      price: '$14',
      description: 'Topped with sautéed onions, bacon, and cheese. Served with sour cream.',
    },
    {
      title: 'Feta Bruschetta',
      price: '$13',
      description: 'Tomatoes, garlic, red onions, fresh basil, and olive oil served on garlic toast with feta cheese, drizzled with balsamic glaze.',
    },
    {
      title: 'Crab Cakes',
      price: '$16',
      description: 'Served with jalapeño dip.',
    },
  ]

};
export default menuData;