import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css'; 

const ButtonGlobal = ({ text, to }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to); 
  };

  return (
    <button className="btn btn-danger btn-lg" onClick={handleClick}>
      {text}
    </button>
  );
};

export default ButtonGlobal;